
$--color-primary: #145969;
$--color-success: #448759;
$--color-danger: #CD6366;
$--color-warning: #DAAF45;


$--color-text-regular: #141414;
$--color-text-secondary: #141414;
$--color-text-placeholder: #636363;
$--color-text-primary: #141414;

//$--color-text-regular: #565655;
//$--color-text-secondary: #b1b1b2;

//$button-text-color: #000000;
//$button-disabled-text-color: #656161;

//$--button-primary-font-color: $button-text-color;
//$--button-danger-font-color: $button-text-color;
//$--button-default-font-color: $button-text-color;
//$--button-disabled-font-color: $button-text-color;
//$--button-danger-font-color: $button-text-color;
//$--button-info-font-color: $button-text-color;


$--border-radius-base: 0;
$--card-border-radius: 0;
$--tag-border-radius: 0;

$--editable-border: 1px dashed #b9b9b9;